import { useGenericGetQuery } from 'hooks/useGenericQuery';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notification } from 'antd';
import BaseService from 'services/api/BaseService';
import { NotificationItem } from 'types/Notifications';
import { NotificationsUrls } from 'services/api/urls';

const urlAll = NotificationsUrls.ALL;
const urlUnread = NotificationsUrls.UNREAD;

export const useNotifications = (unreadOnly?: boolean) => {
  const url = unreadOnly ? urlUnread : urlAll;
  return useGenericGetQuery<NotificationItem[], Error>(url, {
    refetchOnWindowFocus: true,
    gcTime: 0,
    staleTime: 0,
  });
};

export const useMarkNotificationAsRead = () => {
  const { t } = useTranslation('global');
  const queryClient = useQueryClient();

  return useMutation<void, Error, string>({
    mutationFn: async (notificationId: string) => {
      const response = await BaseService.putRequest(
        NotificationsUrls.MARK_AS_READ(notificationId),
        {},
        true,
      );

      const data = await response.json();
      if (response.status === 200 || response.status === 201) {
        return data;
      } else {
        throw new Error(data?.message || t('internet_connexion_error'));
      }
    },
    onSuccess: (_, notificationId) => {
      [urlAll, urlUnread].forEach((key) => {
        queryClient.setQueryData<NotificationItem[] | undefined>(
          [key],
          (data) => {
            if (!data) return data;
            else
              return data.map((n) => ({
                ...n,
                read: n.read || n.id === notificationId,
              }));
          },
        );
      });
    },
    onError: (error) => {
      notification.error({
        message: t('error_marking_notification_as_read'),
        description: error.message,
      });
    },
  });
};
