export default {
  translation: {
    'shared.french': 'English',
    'app.name': 'Plateforme Énergie par Hellio',
    'auth.hero': "Bienvenue sur\nvotre espace client Mon Accompagnateur Rénov'",
  },
  global: {
    'Internal server error':
      "Une erreur inattendue s'est produite. Veuillez réessayer plus tard.",
    hero: 'Bienvenue sur votre espace client',
    password: 'Mot de passe',
    email: 'E-mail',
    logout: 'Se déconnecter',
    cancel: 'Annuler',
    close: 'Fermer',
    back: 'Retour',
    yes: 'Oui',
    no: 'Non',
    validate: 'Valider',
    required: 'Ce champ est obligatoire',
    rdv: 'Prendre rendez-vous',
    max_file: 'Le fichier ne doit pas dépasser {{size}}.',
    internet_connexion_error:
      "Une erreur inattendue s'est produite. Veuillez réessayer plus tard.",
    'email.required': 'Veuillez renseigner une adresse e-mail valide',
    session_expired: 'Votre session a expiré. Veuillez vous reconnecter.',
    file_uploaded: 'Fichier envoyé avec succès',
  },
  login: {
    hero: 'Connexion',
    description: 'Renseignez votre adresse email et votre mot de passe',
    forgot: 'Mot de passe oublié',
    login_error: 'Votre identifiant ou votre mot de passe est incorrect',
    submit: 'Se connecter',
  },
  first_login: {
    hero: 'Première connexion',
    description: 'Renseignez votre mot de passe',
    success: 'La création de votre mot de passe a bien été prise en compte.',
    error: "Votre lien à expiré ou n'est plus valide",
    success_description: 'Création de votre mot de passe',
    success_action: 'Se connecter',
    submit: 'Valider',
    password: 'Mot de passe',
    same: 'Les mots de passes ne sont pas identiques',
    'confirm.password': 'Confirmer mot de passe',
    'confirm.regex.error':
      'Le mot de passe doit contenir 8 caractères :\nune majuscule, un chiffre et un caractère spécial.',
    'confirm.placeholder.password': 'Confirmation du mot de passe',
  },
  change_password: {
    hero: 'Réinitialisation de mot de passe',
    description: 'Veuillez renseigner votre nouveau mot de passe',
    submit: 'Valider',
    success: 'Modification du mot de passe',
    success_description: 'Votre mot de passe a bien été réinitialisé',
    success_action: 'Se connecter',
    password: 'Nouveau mot de passe',
    same: 'Les mots de passes ne sont pas identiques',
    'confirm.password': 'Confirmer mot de passe',
    'confirm.regex.error':
      'Le mot de passe doit contenir 8 caractères :\nune majuscule, un chiffre et un caractère spécial.',
    'confirm.placeholder.password': 'Confirmation du mot de passe',
  },
  404: {
    hero: 'Page introuvable',
    description: "La page que vous cherchez n'existe pas ou a été déplacée.",
    back: "Retour à l'accueil",
  },
  reset_password: {
    hero: 'Mot de passe oublié',
    description:
      'Veuillez saisir votre email de connexion afin de recevoir le lien de réinitialisation de votre mot de passe.',
    submit: 'Valider',
    error: "Votre lien à expiré ou n'est plus valide",
    back: 'Retour',
    success: 'Réinitialisation du mot de passe',
    success_alert:
      "Nous vous avons envoyé un e-mail pour réinitialiser votre mot de passe. Pour créer votre nouveau mot de passe, il vous suffit de cliquer sur le lien contenu dans l'e-mail et d'en saisir un nouveau.",
    success_description:
      "<strong>Vous n'avez pas reçu cet e-mail</strong><br> Vérifiez vos courriers indésirables ou contactez :<br>",
  },
  select: {
    all: 'Tout sélectionner',
    none: 'Tout désélectionner',
  },
  file: {
    default_label: 'Fichiers',
    delete: 'Supprimer le fichier {{file}}',
    default_drop_zone_label_one: 'Veuillez glisser votre fichier ici',
    default_drop_zone_label_other: 'Veuillez glisser vos fichiers ici',
    or: 'ou',
    browse: 'charger depuis vos fichiers',
    max_size: 'Max',
    max_files_other: '{{count}} fichiers maximum',
    max_files_one: 'Un fichier maximum',
    max_files_zero: 'Aucun fichier restant',
    max_error_files: 'Vous ne pouvez pas envoyer plus de {{maxFiles}} fichiers',
    max_error_size_one:
      "Un fichier dépasse 10Mo, vous pouvez l'envoyer par email à :\n" +
      '<Link href="mailto:serviceclient.energie@hellio.com">serviceclient.energie@hellio.com</Link> avec la référence de la demande',
    max_error_size_other:
      'Certains fichiers dépassent 10Mo, vous pouvez envoyer l’envoyer par email à :\n' +
      '<Link href="mailto:serviceclient.energie@hellio.com">serviceclient.energie@hellio.com</Link> avec la référence de la demande',
    named_max_error_size_one: 'Le fichier: {{name}}, dépasse 10Mo',
    named_max_error_size_other: 'Les fichiers: {{name}}, dépassent 10Mo',
  },
  profil: {
    title: 'Mes identifiants',
    subtitle: 'Veuillez renseigner votre nouveau mot de passe',
    identity: 'Identité',
    firstname: 'Prénom',
    mobilephone: 'Téléphone mobile',
    lastname: 'Nom',
    email: 'E-mail',
    update_password: 'Modifier mot de passe',
    oldPassword: 'Ancien mot de passe',
    newPassword: 'Nouveau mot de passe',
    confirmPassword: 'Confirmer le nouveau mot de passe',
    validate: 'Valider',
    cancel: 'Annuler',
    change_password_success_title: 'Nouveau mot de passe bien enregistré',
    change_password_success_subtitle:
      'Votre changement de mot de passe a bien été pris en compte',
  },
  project: {
    details: 'Voir détails',
    owner: 'Propriétaire',
    build_date: 'Année de construction',
    surface: 'Surface',
    meters: 'm²',
    badge_power: 'Étiquette énergétique',
    project: 'Projet',
    documents: 'Documents',
    my_project: 'Mon projet',
    my_mar: "Mon Accompagnateur Rénov'",
    house: 'Logement',
    build_year: 'Année de construction',
    scenario: 'Scénario retenu',
    budget: 'Budget',
    amount: 'Montant des travaux',
    mpra_help: 'Aides MaPrimeRénov’',
    rest: 'Reste à charge',
    power_class: 'Classe énergétique',
    power_class_before: 'Avant projet',
    power_class_after: 'Après projet',
    power_class_gap_other: 'Saut de {{count}} classes énergétiques',
    power_class_gap_one: 'Saut d’une classe énergétique',
    power_class_gap_zero: 'Pas de changement de classe énergétique',
    fiscal_house: 'Foyer',
    fiscal_salary: 'Revenu fiscal',
    mpra: 'MaPrimeRénov’',
  },
  documents: {
    upload: 'Importer un fichier',
    title: 'Documents',
    file: 'Fichier',
    file_type: 'Type de fichier',
    actions: 'Actions',
    by_hellio: 'Déposé par Hellio le {{date}}',
    by_me: 'Déposé par moi le {{date}}',
    download: 'Télécharger le fichier',
    update: 'Mettre à jour le fichier',
    empty: "Aucun document n'a été déposé",
  },
  document_modal: {
    placeholder: 'Sélectionner',
    title: 'Importer un fichier',
    description:
      'Veuillez déposer votre document qui sera étudié par votre Accompagnateur Rénov’.',
    category: 'Type de document',
    max_size_error: 'Fichier trop volumineux (max {{size}}Mo)',
  },
  documents_categories: {
    all: 'Tous les documents',
    devis: 'Devis',
    devis_hellio_inge: 'Devis Hellio Ingénierie',
    preuve_virement: 'Preuve de virement',
    attestation_travaux: 'Attestation de travaux',
    piece_administratives: 'Pièces administratives',
    restitution_audit: 'Audit énergétique',
    devis_travaux: 'Devis travaux',
    grille_analyse_logement: "Grille d'analyse du logement",
  },
  documents_category: {
    avis_d_imposition__1_: 'Avis d’imposition',
    avis_d_imposition: 'Avis d’imposition',
    fichier_dpe: 'DPE',
    audit_energetique: 'Audit énergétique',
    audit_energetique_final: "Restitution de l'audit",
    rib_hi: 'RIB',
    grille_d_analyse_synthese: "Grille d'analyse du logement",
    fichier_devis: 'Devis',
    fichier_devis__1_: 'Devis',
    devis_mar_signe: 'Devis signé',
    plans: 'Plan de configuration du bien',
    attestation_sur_l_honneur__proprietaire_bailleurs_:
      "Attestation sur l'honneur",
    justificatif_de_propriete: 'Titre de propriété',
    avis_de_virement_hi: 'Preuve de virement',
    attestation_travaux__facture_: 'Attestation de travaux',
  },
  avatar: {
    avatar_change_success: 'Votre avatar a bien été modifié',
  },
  meeting: {
    cancelled: 'Reunion annulée avec succès',
    title: 'Mon rendez-vous',
    phone_description: 'Le rendez-vous se fera par téléphone.',
    place_description: 'Le rendez-vous se fera sur place',
    video_description: 'Le rendez-vous se fera en visioconférence',
    phone_title: 'Par téléphone',
    place_title: 'Sur place',
    video_title: 'En visioconférence',
    take: 'Prendre rendez-vous',
    cancel: 'Annuler',
    cancel_title: 'Annulation du rendez-vous',
    cancel_description: 'Souhaitez-vous vraiment annuler ce rendez-vous ?',
    reschedule: 'Replannifier',
  },
  steppers: {
    show_all: 'Afficher toutes les étapes',
    hide_all: 'Masquer toutes les étapes',
  },
  resend_link_first_login: {
    title: 'Votre lien de connexion a expiré',
    action: 'Recevoir un nouveau lien',
    description:
      'Votre lien de connexion a expiré. Pour des raisons de sécurité, les liens de connexion sont valables uniquement pendant une durée limitée.',
    success_title: 'Votre lien de connexion a été envoyé par mail',
    success_description:
      "<strong>Vous n'avez pas reçu cet e-mail</strong><br> Vérifiez vos courriers indésirables ou contactez :<br>",
  },
  notifications: {
    title: 'Notifications',
    empty: 'Vous n’avez pas de notification',
    DEPOTS_PIECES_ADMINISTRATIVES_title:
      'Déposez vos pièces administratives pour continuer le traitement de votre dossier.',
    DEPOSER_DEVIS_title:
      'Déposez votre devis pour continuer le traitement de votre dossier.',
    RAPPELER_RDV_title: 'Rappel : Votre rendez-vous est prévu pour demain.',
    PLANIFIER_RDV_title:
      'Planifiez un rendez-vous découverte avec votre accompagnateur rénov pour avancer dans votre projet',
  },
};
