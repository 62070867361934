import React, { Fragment, lazy, Suspense } from 'react';
import { AuthLayout, AppLayout } from 'layouts';
import { Redirect, Route, Switch } from 'react-router-dom';
import { LoadingScreen } from 'components';
import { AuthGuard, AppGuard } from 'routes/guards';
import { RouteConfig } from 'react-router-config';

export const routesConfig: RouteConfig[] = [
  {
    path: '/errors/error-404',
    exact: true,
    component: lazy(() => import('views/errors/404.page')),
    layout: Fragment,
    guard: Fragment,
  },
  {
    path: '/',
    exact: true,
    component: lazy(() => import('views/projects/List/Projects.page')),
    layout: AppLayout,
    guard: AppGuard,
  },
  {
    path: '/projets/:id/:tab',
    exact: true,
    component: lazy(() => import('views/projects/Details/ProjectDetails.page')),
    layout: AppLayout,
    guard: AppGuard,
  },
  {
    path: '/projets/:id/:tab/:categoryId',
    exact: true,
    component: lazy(() => import('views/projects/Details/ProjectDetails.page')),
    layout: AppLayout,
    guard: AppGuard,
  },
  {
    path: '/profile',
    exact: true,
    component: lazy(() => import('views/user/UserProfile.page')),
    layout: AppLayout,
    guard: AppGuard,
  },
  {
    path: '/reinitialiser-mot-de-passe',
    exact: true,
    component: lazy(() => import('views/auth/UpdatePassword.page')),
    layout: AuthLayout,
    guard: AuthGuard,
  },
  {
    path: '/creation-compte',
    exact: true,
    component: lazy(() => import('views/auth/UpdatePassword.page')),
    layout: AuthLayout,
    guard: AuthGuard,
  },
  {
    path: '/connexion',
    exact: true,
    component: lazy(() => import('views/auth/Login.page')),
    layout: AuthLayout,
    guard: AuthGuard,
  },
  {
    path: '/mot-de-passe-oublie',
    exact: true,
    component: lazy(() => import('views/auth/ResetPasswordInit.page')),
    layout: AuthLayout,
    guard: AuthGuard,
  },
  {
    path: '/first-login',
    exact: true,
    component: lazy(() => import('views/auth/UpdatePassword.page')),
    layout: AuthLayout,
    guard: AuthGuard,
  },
  {
    path: '/lien-non-valide',
    exact: true,
    component: lazy(() => import('views/auth/ResendLink.page')),
    layout: AuthLayout,
    guard: AuthGuard,
  },
  {
    path: '*',
    component: () => <Redirect to="/errors/error-404" />,
    layout: Fragment,
    guard: Fragment,
  },
];

const renderRoutes = (routes: RouteConfig[]) =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component: any = route.component;
          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
