export enum NotificationType {
  DEPOSER_DEVIS = 'DEPOSER_DEVIS',
  RAPPELER_RDV = 'RAPPELER_RDV',
  DEPOTS_PIECES_ADMINISTRATIVES = 'DEPOTS_PIECES_ADMINISTRATIVES',
  PLANIFIER_RDV = 'PLANIFIER_RDV',
}

export interface NotificationItem {
  id: string;
  transactionId?: string;
  userId: string;
  title: string;
  description: string;
  type: NotificationType;
  read: boolean;
  createdAt: string;
  updatedAt: string;
  date: string;
  redirectUrl?: string;
}
