import { ReactComponent as CalendarFilled } from 'assets/icons/CalendarFilled.svg';
import { ReactComponent as FilePdfFilled } from 'assets/icons/FilePdfFilled.svg';
import { ReactComponent as Badge } from 'assets/icons/Badge.svg';
import { NotificationItem, NotificationType } from 'types/Notifications';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import clsx from 'clsx';
import formatTime from 'utils/date';
import { useMarkNotificationAsRead } from 'layouts/notifications_hook';
import { useHistory } from 'react-router';

function NotificationIcon({
  notification,
}: {
  notification: NotificationItem;
}) {
  switch (notification.type) {
    case NotificationType.DEPOSER_DEVIS:
    case NotificationType.DEPOTS_PIECES_ADMINISTRATIVES:
      return <FilePdfFilled />;
    default:
      return <CalendarFilled />;
  }
}

interface NotificationsPopoverProps {
  notifications: NotificationItem[];
  onClose: () => void;
  loading: boolean;
}

export default function NotificationsList({
  onClose,
  notifications,
  loading,
}: NotificationsPopoverProps) {
  const { t } = useTranslation('notifications');
  const history = useHistory();
  const { mutate } = useMarkNotificationAsRead();

  return (
    <div
      className={
        'bg-white rounded px-1 py-5 flex flex-col gap-4 max-w-[90vw] lg:w-[600px] w-[320px]'
      }
    >
      <h4 className={'text-xl font-semibold flex items-center justify-between'}>
        <span>{t('title')}</span>
        <Button
          onClick={onClose}
          className={'border-none'}
          variant={'text'}
          shape="circle"
          icon={<CloseOutlined />}
        />
      </h4>
      {!loading && notifications.length === 0 && <p>{t('empty')}</p>}
      {loading && (
        <div className={'flex justify-center'}>
          <Spin />
        </div>
      )}
      <div>
        {notifications
          .sort((a, b) => {
            return new Date(b.date).getTime() - new Date(a.date).getTime();
          })
          .map((notification) => (
            <div
              onClick={() => {
                onClose();
                mutate(notification.id);
                history.push(
                  `/projets/${notification.transactionId}/documents/all`,
                );
              }}
              key={notification.id}
              className={clsx(
                'border-b border-solid border-divider hover:bg-Bleu-clair-hellio p-3 cursor-pointer',
                {
                  'bg-BleuHellio-1': !notification.read,
                },
              )}
            >
              <div className={'flex items-end gap-2 relative'}>
                {!notification.read && (
                  <div className={'absolute top-0 right-0'}>
                    <Badge />
                  </div>
                )}
                <div className={'flex grow items-start gap-2'}>
                  <div className="p-2 rounded-md bg-BleuHellio-1">
                    <NotificationIcon notification={notification} />
                  </div>
                  <div className={'flex flex-col gap-3.5'}>
                    <p
                      className={
                        'font-semibold !leading-none text-grayTransparent text-sm'
                      }
                    >
                      {t(notification.title)}
                    </p>
                    <p
                      className={
                        'text-sm text-graySemiTransparent !leading-none'
                      }
                    >
                      {notification.description}
                    </p>
                  </div>
                </div>
                <p className={'text-[11px] text-Industriels !leading-none'}>
                  {formatTime(notification.date)}
                </p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
