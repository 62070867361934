import { useTranslation } from 'react-i18next';
import type { DefaultError } from '@tanstack/query-core';
import { useQuery } from '@tanstack/react-query';
import BaseService from 'services/api/BaseService';
import type { UndefinedInitialDataOptions } from '@tanstack/react-query/src/queryOptions';

export function useGenericGetQuery<TData, TError = DefaultError>(
  url: string,
  options?: Omit<
    UndefinedInitialDataOptions<unknown, TError, TData, [string]>,
    'queryKey' | 'queryFn'
  >,
) {
  const { t } = useTranslation();

  return useQuery<unknown, TError, TData, [string]>({
    queryKey: [url],
    queryFn: async () => {
      let data: any;
      try {
        const response = await BaseService.getRequest(url, true);
        data = await response.json();
        if ([200, 201].includes(response.status)) {
          return data;
        }
      } catch (e: any) {
        throw new Error(t('global:internet_connexion_error'));
      }
      throw new Error(data?.message || t('global:internet_connexion_error'));
    },
    ...options,
  });
}
