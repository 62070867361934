import {
  differenceInHours,
  differenceInMinutes,
  format as fnsFormat,
  isThisYear,
  isYesterday,
} from 'date-fns';
import { fr } from 'date-fns/locale';
export const formatDate = (date: string | Date | number, format: string) => {
  return fnsFormat(new Date(date), format, { locale: fr });
};

function formatTime(date: string | Date | number) {
  const now = new Date();

  if (differenceInMinutes(now, date) < 60) {
    return differenceInMinutes(now, date) + 'm';
  }
  if (differenceInHours(now, date) < 24) {
    return formatDate(date, 'HH:mm');
  }

  if (isYesterday(date)) {
    return 'Hier';
  }

  if (isThisYear(date)) {
    return formatDate(date, 'dd MMM');
  }

  return formatDate(date, 'dd MMM yyyy');
}

export default formatTime;
